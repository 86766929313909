<template>
    <div class="video-container">
        <video muted loop autoplay playsinline class="centered-video">
            <source :src="require('@/assets/images/video/VIDEO GIF NQA WEB.mp4')" type="video/mp4">
        </video>
    </div>
</template>

<script>
export default {
    name: "Tour",
};
</script>

<style scoped>

.video-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    min-height: 100vh; 
    padding: 0 10%; 
    box-sizing: border-box;
    margin-top: 50px;
}


.centered-video {
    width: 90%; 
    max-width: 1200px; 
    height: 100%; 
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); 
}


@media (max-width: 768px) {
    .video-container {
        padding: 0 5%; 
    }
    .centered-video {
        max-width: 90%; 
    }
}
</style>
