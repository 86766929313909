<template>
    <section class="section publish">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-10">
                    <div class="publish__inner">
                        <div class="row justify-content-center">
                            <div class="col-15">
                                <div class="section__header wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="300ms">
                                    <h2 class="h2 text-center">{{ labelsState['why_choose_nqa_bot'] || '¿Why choose NQA ChatBot??' }}</h2>
                                    <p class="p">{{ labelsState['why_choose_nqa_bot_description'] || 'NQA ChatBot is the artificial intelligence solution designed to revolutionize the way your company interacts with customers. Our intelligent chatbot combines personalized attention, data security, and 24/7 availability to offer an exceptional experience that improves your operations and increases customer satisfaction.' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row items-gap">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['personalized_attention'] || 'Personalized Attention' }}</h5>
                                        <p>{{ labelsState['personalized_attention_description'] || 'Provide fast and comprehensive answers from any device, at any time of the day. With NQA ChatBot, your customers will receive efficient and personalized attention.' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['connection_with_social_networks'] || 'Connection with Social Networks' }}</h5>
                                        <p>{{ labelsState['connection_with_social_networks_description'] || 'Integrate your digital channels into a single tool. Easily connect it to social networks like WhatsApp, Instagram and Facebook.' }}</p>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['sending_emails'] || 'Sending Emails' }}</h5>
                                        <p>{{ labelsState['sending_emails_descrption'] || 'NQA Bot automatically sends personalized emails to maintain effective communication.' }}</p>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['generate_sales_and_appointments_automatically'] || 'Generate Sales and Appointments Automatically' }}</h5>
                                        <p>{{ labelsState['generate_sales_and_appointments_automatically_description'] || 'NQA Bot, optimize your sales and customer service flow. Automate the appointment scheduling process and make sales without manual intervention. Convert prospects into customers quickly and efficiently.'}}</p>
                                    </div>                                    
                                </div>
                            </div>
                            
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="600ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['customer_service'] || 'Customer service 24/7' }}</h5>
                                        <p>{{ labelsState['customer_service_description'] || 'With ChatBot NQA, connect with your customers in real time 24/7. Automatically answer their questions, personalize interactions to your brand, and save time. Best of all, you do not need any programming skills to implement it.' }}</p>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <div class="publish__single wow fadeInUp" data-wow-duration="600ms"
                                    data-wow-delay="300ms">
                                    <div class="thumb">
                                        <i class="fa-solid fa-square-check"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="h5">{{ labelsState['protected_information'] || 'Protected Information' }}</h5>
                                        <p>{{ labelsState['protected_information_description'] || 'Your security is our priority. At NQA, we ensure that your company and customer information is 100% protected. With advanced encryption technologies, we keep your data confidential and secure.'}}</p>
                                    </div>
                                </div>
                            </div>
                            

                        </div>
                        <div class="section__content-cta text-center wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                            <a :href="registerUrl" class="btn btn--senary" target="_blank">
                                {{ labelsState['get_free_demo'] || 'Get a totally free demo' }}
                            </a>
                            <img :src="require('@/assets/images/frame-three.png')" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Publish",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
        };
    },
};
</script>
