<template>
<section id="pricing" class="section pricing-two bg-img">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-xl-6">
                <div class="section__header wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                    <h2 class="h2">{{ labelsState['ready_to_start'] || '¿Ready to start?' }}</h2>
                    <h2 class="h2">{{ labelsState['start_today_free'] || 'Start today totally free' }}</h2>
                </div>
            </div>
        </div>
        <!-- Filter Buttons -->
        <div class="row justify-content-center mb-4">
            <div class="col-auto">
                <button 
                    class="btn btn--filter" 
                    :class="{ active: filter === 'all' }" 
                    @click="filter = 'all'; applyFilter();">
                    {{ labelsState['all_plans'] || 'All Plans' }}
                </button>
                <button 
                    class="btn btn--filter" 
                    :class="{ active: filter === 'free' }" 
                    @click="filter = 'free'; applyFilter();">
                    {{ labelsState['free'] || 'Free' }}
                </button>
                <button 
                    class="btn btn--filter" 
                    :class="{ active: filter === 'monthly' }" 
                    @click="filter = 'monthly'; applyFilter();">
                    {{ labelsState['monthly'] || 'Monthly' }}
                </button>
                <button 
                    class="btn btn--filter" 
                    :class="{ active: filter === 'annual' }" 
                    @click="filter = 'annual'; applyFilter();">
                    {{ labelsState['annual'] || 'Annual' }}
                </button>
                <button 
                    class="btn btn--filter" 
                    :class="{ active: filter === 'enterprise' }" 
                    @click="filter = 'enterprise'; applyFilter();">
                    {{ labelsState['enterprise'] || 'Enterprise' }}
                </button>
            </div>
        </div>
        <!-- Leaked plans -->
        <div class="row items-gap">
            <div 
                class="col-12 col-md-6 col-lg-6 col-xxl-3" 
                v-for="plan in filteredPlans" 
                :key="plan.id">
                <div class="pricing-two__single wow fadeInUp" data-wow-duration="600ms" data-wow-delay="900ms">
                    <h5 class="h5 yel">{{ plan.subscription_plan }}</h5>
                    <div class="meta text-center">
                        <div class="content">
                            <h2 class="price">{{ plan.amount ? '$' + formatNumber(plan.amount) : 'Free' }} <sup class="mt-4 cop-super">USD</sup></h2>
                            <h2 class="price">{{ plan.amount_cop ? '$' + formatNumber(plan.amount_cop) : 'Free' }} <sup class="mt-4 cop-super">COP</sup></h2>
                        </div>
                        <p>{{ plan.frequency }} {{ labelsState['month_s'] || 'Month(s)' }}</p>
                    </div>
                    <hr>
                    <ul>
                        <li><i class="fa-solid fa-check"></i> {{ labelsState['credits'] || 'credits' }}: {{ formatNumber(plan.credits) }}</li>
                    </ul>
                    <hr>
                    <p class="text-white-dark mt-2" v-html="plan.description"></p>
                    <hr>
                    <a 
                        :href="subsUrl"
                        target="_blank" 
                        class="btn btn--septenary">
                        {{ plan.is_free ? (labelsState['free_demo'] || 'Free Demo') : (labelsState['suscribe_now'] || 'Subscribe Now') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    name: "Pricing",
    setup() {
        const subsUrl = `${process.env.VUE_APP_DASHBOARD_URL}/subscriptions`;
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();
        const plans = ref([]);
        const filteredPlans = ref([]);
        const filter = ref("all"); 

        const fetchPlans = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_HOST_BILLING}/plans_open`);
                plans.value = response.data.plans.sort((a, b) => {
                    if (a.is_free && !b.is_free) return -1;
                    if (!a.is_free && b.is_free) return 1;

                    if (a.is_enterprise && !b.is_enterprise) return 1; 
                    if (!a.is_enterprise && b.is_enterprise) return -1;

                    if (a.frequency !== b.frequency) {return a.frequency - b.frequency;}
                    if (a.amount !== b.amount) {return (a.amount || 0) - (b.amount || 0);}
                    return 0;
                });
                applyFilter();
    
            } catch (error) {
                console.error("Error fetching plans:", error);
            }
        };

        const applyFilter = () => {
            if (filter.value === "all") {
                
                filteredPlans.value = plans.value;
            } else if (filter.value === "free") {
                
                filteredPlans.value = plans.value.filter(plan => plan.is_free);
            } else if (filter.value === "monthly") {
                
                filteredPlans.value = plans.value.filter(
                    plan => plan.frequency === 1 && !plan.is_free && !plan.is_enterprise
                );
            } else if (filter.value === "annual") {
                
                filteredPlans.value = plans.value.filter(
                    plan => plan.frequency === 12 && !plan.is_free && !plan.is_enterprise
                );
            } else if (filter.value === "enterprise") {
                
                filteredPlans.value = plans.value.filter(plan => plan.is_enterprise);
            }
        };

        onMounted(() => {
            fetchPlans();
        });

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        
        function formatNumber(value) {
            if (value === null || value === undefined) return '0';
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }


        return {
            labelsState,
            languageCodes,
            plans,
            filteredPlans,
            subsUrl,
            filter,
            applyFilter,
            formatNumber,
        };
    },
};
</script>

<style>
.pricing-two {
    background-color: #111827;
}

.text-center {
    text-align: center;
}

.price {
    font-size: 2rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: white;
}

.frequency {
    font-size: 1.2rem;
    color: white;
    display: block;
    margin-bottom: 1rem;
}

.meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.price {
    font-size: 2rem;
    margin: 0.5rem 0;
    font-weight: bold;
    color: white;
    text-align: center;
}

.cop-super {
    font-size: 0.6em;
    vertical-align: super;
    color: white;
}

.meta .content {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
    color: white;
}

ul li {
    margin: 0.5rem 0;
}

h2.price, small.frequency {
    display: block;
    text-align: center;
    margin: 0 auto;
}

.pricing-two__single {
    padding: 20px;
    background-color: #1f2937;
    border-radius: 10px;
}

.btn--filter {
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid #00CC99;
    color: #00CC99;
    background: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.btn--filter:hover {
    background-image:linear-gradient(90deg, #00CC99 0%, #003366 95.62%);
    color: white;
}

.btn--filter.active {
    background-color: #00CC99;
    color: #fff;
    font-weight: bold; 
    border: 1px solid #00CC99;
}
</style>
