<template>
    <section class="section generate pb-0">
        <div class="container">
            <div class="row items-gap">
                <div class="col-12 col-lg-6 col-xl-5">
                    <div class="generate__content wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <div class="section__header text-start">
                            <h2 class="h2">
                                {{ labelsState['automate_your_customer_services'] || 'We develop Intelligent Chatbots for your company, improve customer service with' }}
                                <span class="grd">{{ labelsState['nqa_chatbot'] || 'NQA ChatBot' }}</span>
                            </h2>
                        </div>
                        <div>
                            <div class="generate__content-btn generate__content-btn-active">
                                <div class="thumb">
                                    <i class="tech-pen"></i>
                                </div>
                                <div class="content">
                                    <h5 class="h5">{{ labelsState['custom_chat'] || 'Personalized Chat for Companies' }}</h5>
                                    <p>{{ labelsState['custom_chat_description'] || "Create a chatbot that matches your brand identity. Configure colors, style, and tone of communication to offer a unique experience aligned with your business values." }}</p>
                                </div>
                            </div>
                            <div class="generate__content-btn">
                                <div class="thumb">
                                    <i class="tech-cart"></i>
                                </div>
                                <div class="content">
                                    <h5 class="h5">{{ labelsState['ecommerce_growth'] || 'Grow your business' }}</h5>
                                    <p>{{ labelsState['ecommerce_growth_description'] || "Improve customer experience with intelligent chatbots that respond in real time, increasing satisfaction and your sales" }}</p>
                                </div>
                            </div>
                            <div class="generate__content-btn">
                                <div class="thumb">
                                    <i class="tech-mike"></i>
                                </div>
                                <div class="content">
                                    <h5 class="h5">{{ labelsState['social_connection'] || 'Connect your service channels' }}</h5>
                                    <p>{{ labelsState['social_connection_description'] || 'Integra redes sociales y otros medios para centralizar tus respuestas y mejorar la experiencia de tus clientes.' }}</p>
                                </div>
                            </div>                            
                            <div class="generate__content-btn">
                                <div class="thumb">
                                    <i class="tech-globe"></i>
                                </div>
                                <div class="content">
                                    <h5 class="h5">{{ labelsState['join_revolution'] || 'Join the change' }}</h5>
                                    <p>{{ labelsState['join_revolution_description'] || 'Take your business to the next level with the power of chatbots and Artificial Intelligence' }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-7">
                    <div class="generate__thumb wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <div class="tab-content active">
                            <img :src="require('@/assets/images/generate/custom-chat.png')" alt="image">
                        </div>
                        <div class="tab-content">
                            <img :src="require('@/assets/images/generate/growing.png')" alt="image">
                        </div>
                        <div class="tab-content">
                            <img :src="require('@/assets/images/generate/conection.png')" alt="image">
                        </div>
                        <div class="tab-content">
                            <img :src="require('@/assets/images/generate/revolution.png')" alt="image">
                        </div>   
                        <div class="anime anime--light">
                            <img :src="require('@/assets/images/anime-four.png')" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Generate",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        return {
            labelsState,
            languageCodes,
            changeLanguage,
        };
    },
    mounted() {
        const tabContents = document.querySelectorAll('.tab-content');
        const tabButtons = document.querySelectorAll('.generate__content-btn');
        tabContents[0].style.display = 'block';
        tabButtons[0].classList.add('generate__content-btn-active');
        tabButtons.forEach((button, index) => {
            button.addEventListener('click', () => {
                tabButtons.forEach((btn) => btn.classList.remove('generate__content-btn-active'));
                tabContents.forEach((content) => content.style.display = 'none');
                button.classList.add('generate__content-btn-active');
                tabContents[index].style.display = 'block';
                tabContents[index].style.animation = 'fadeIn 900ms';
            });
        });
    }
};
</script>

<style scoped>
.tab-content {
    display: none;
}
</style>
