<template>
    <section id="about" class="section clone about-main">
        <div class="container">
            <div class="about-layout">
                <div class="about__content">
                    <div class="about__image">
                        <img :src="require('@/assets/images/aboutUs.png')" alt="Image">
                    </div>
                    
                    <div class="clone__content section__content wow fadeInUp" data-wow-duration="600ms"
                        data-wow-delay="300ms">
                        <h2 class="h2 text-center">{{ labelsState['what_is_nqa'] || 'What is Artificial Neuro Quantum?' }}</h2>
                        <div class="paragraph text-center">
                            <p class="fw-5 text-lg">{{ labelsState['about_description'] || 'Neuro Quantum Artificial (NQA) is an advanced artificial intelligence assistant designed to drive digital transformation and business automation in key sectors such as e-commerce, retail, banking and education. With NQA ChatBot, your company can automate key processes, optimize strategic decision-making and improve operational efficiency, allowing you to offer a more agile, efficient and personalized service to your customers.' }}</p>
                        </div>
                        <div class="features-list">
                            <div class="feature">
                                <i class="fa-solid fa-magnifying-glass"></i>
                                <p>{{ labelsState['without_complicated_searches'] ||'Without complicated searches'}}</p>                                
                            </div>
                            <div class="feature">
                                <i class="fa-solid fa-check"></i>
                                <p>{{ labelsState['accurate_and_personalized_answers'] ||'Accurate and personalized answers'}}</p>                                                                                              
                            </div>
                            <div class="feature">
                                <i class="fa-solid fa-clock"></i>
                                <p>{{ labelsState['save_time_and_effort'] ||'Save time and effort'}}</p>
                            </div>
                            <div class="tour__content-cta">
                                <router-link :to="'/Appointment'" class="btn btn--primary">
                                    {{ labelsState['schedule_appointmen'] || "Schedule an appointment for a demo IT IS FREE" }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import { computed, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "Introduction",
    setup() {
        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();

        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };

        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);
        const registerUrl = `${process.env.VUE_APP_DASHBOARD_URL}/auth/cover-register`;

        return {
            labelsState,
            languageCodes,
            changeLanguage,
            registerUrl,
        };
    },
};
</script>
