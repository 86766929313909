<template>
  <div :class="{ 'modal-open': showModal }">
    <div class="my-app home-two-dark">
      <Header2 class="header--dark" />
      <MobileMenu />
        <section id="Events" class="section cta-two pb-0">
        
            <div class="row">
                <div class="col-12">
                    <div class="section__header wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <h4 class="h2">{{ labelsState['events'] || 'Events' }}</h4>
                    </div>
                    <div class="cta-two__inner bg-img wow fadeInUp" data-wow-duration="600ms" data-wow-delay="300ms">
                        <iframe v-if="sharebotChatUrl" :src="sharebotChatUrl" frameborder="0" width="100%" height="500px"></iframe>
                    </div>
                </div>
            </div>      
       
    </section>
    <Footer2 />
    </div>
    <BackToTop />
</div>    
</template>

<script>
import { ref, onMounted,computed  } from 'vue';
import axios from 'axios';
import { useLabelsStore } from '@/stores/labels';
import { useLanguageStore } from '@/stores/language';
import "../assets/css/global.css";
import Footer2 from "../components/layout/Footer2.vue";
import Header2 from "../components/layout/Header2.vue";
import MobileMenu from "../components/layout/MobileMenu.vue";
import BackToTop from "../components/common/BackToTop.vue";

export default {
    name: "Events",
    components: { Header2, MobileMenu, Footer2, BackToTop },
    setup() {
        const sharebotChatUrl = ref('');
        const fetchDefaultValues = async () => {
            try {
                const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/default_parameter_cookie`, {
                    withCredentials: false,
                });
                const response_data = response.data;
                sharebotChatUrl.value = response_data['message']['event_url'];
            } catch (error) {
                console.error('Error fetching default values:', error);
            }
        };

        const labelsStore = useLabelsStore();
        const languageStore = useLanguageStore();
        const changeLanguage = (languageCode) => {
            labelsStore.fetchAndSetLabels(languageCode);
        };
        const labelsState = computed(() => labelsStore.labels);
        const languageCodes = computed(() => languageStore.codes);

        onMounted(() => {
            fetchDefaultValues();
        });

        return {
            sharebotChatUrl,
            labelsState,
            languageCodes,
            changeLanguage,
        };
    }
};
</script>

<style scoped>

.cta-two {
    margin-top: 200;  
    padding-top: 100;
    }
    .cta-two__inner {
        background-color: #111827;
        padding: 0px; 
        margin: 0 auto;
        border-radius: 10px;
        min-height: 600px;

    }
</style>