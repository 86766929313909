import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import Home2 from '@/pages/Home2.vue';
import About from '@/pages/About.vue';
import Error from '@/pages/Error.vue';
import TermsAndConditions from '@/pages/TermsAndConditions.vue';
import Policies from '@/pages/Policies.vue';
import DataTreatment from '@/pages/DataTreatment.vue';
import Appointment from '@/pages/Appointment.vue'
import Events from '@/pages/Events.vue'
import Blog from '@/pages/Blog.vue';
import Contact from '@/pages/Contact.vue';
import NQAWidget from '@/pages/NQAWidget.vue';
Vue.use(Router);

let refCode = null;
async function handleRefCode() {
  const urlParams = new URLSearchParams(window.location.search);
  const urlRefCode = urlParams.get('refcode');
  try {
    if (urlRefCode) {
      const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/refcode`, {
        params: { refcode: urlRefCode },
        withCredentials: true
      });

      if (response.status === 200) {
        refCode = urlRefCode;
      }
    } else {
      const response = await axios.get(`${process.env.VUE_APP_HOST_ROLESANDADMINISTRATION}/refcode`, {
        withCredentials: true
      });

      if (response.status === 200 && response.data.refcode) {
        refCode = response.data.refcode;
      }
    }
  } catch (error) {
    console.error('Error fetching refcode:', error);
  }
}
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home Two',
      component: Home2,
      meta:{
        useNQAWidget: true,
      }

    },
    {
      path: '/about',
      name: 'About Us',
      component: About,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/404',
      name: '404 Not Found',
      component: Error,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/TermsAndConditions',
      name: 'TermsAndConditions',
      component: TermsAndConditions,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/DataTreatment',
      name: 'DataTreatment',
      component: DataTreatment,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/Policies',
      name: 'Policies',
      component: Policies,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      component: Contact,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/Appointment',
      name: 'Appointment',
      component: Appointment,
      meta:{
        useNQAWidget: true,
      }
    },
    {
      path: '/Events',
      name: 'Events',
      component: Events,
      meta:{
        useNQAWidget: true,
      }      
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
let widgetInstance = null;

function initializeWidget() {
  if (!document.querySelector('#nqa-widget')) {
    const widgetElement = document.createElement('div');
    widgetElement.id = 'nqa-widget';
    document.body.appendChild(widgetElement);

    const WidgetConstructor = Vue.extend(NQAWidget);
    widgetInstance = new WidgetConstructor().$mount('#nqa-widget');
  }

  if (widgetInstance) {
    widgetInstance.initializeWidget();
  }
}

function removeWidget() {
  const widgetElement = document.querySelector('#nqa-widget');
  if (widgetElement) {
    if (widgetInstance) {
      widgetInstance.$destroy();
      widgetInstance = null;
    }
    widgetElement.remove();
  }
}
router.beforeEach(async (to, from, next) => {
  if (to.meta.useNQAWidget) {
    initializeWidget();
  } else {
    removeWidget();
  }
  await handleRefCode();
  document.title = to.meta.title || 'Neuro Quantum Artificial';
  next();
});
export { refCode };

export default router;
